<template>
  <section class="text-center py-10">
    <!-- <p class="text-gray-600 mb-2">
      please join us for our wedding celebration on
    </p> -->
    <h2 class="text-4xl font-bold text-white">February 15, 2025</h2>
    <p class="text-gray-500 mt-1" v-if="daysUntilWedding >= 0">
      {{ daysUntilWedding }} days until we say "I Do!"
    </p>
    <p class="text-gray-500 mt-1" v-else>We are married!!</p>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import { differenceInDays } from "date-fns";

const weddingDate = ref(new Date(2025, 1, 15)); // February 15, 2025
const today = ref(new Date());

const daysUntilWedding = computed(() => {
  return differenceInDays(weddingDate.value, today.value);
});
</script>
